.navbar {
    background-color: black;
    background-image:url('../../assets/images/black-nav-bg.png');
    padding:10px 0!important;
  }

  .offcanvas {
    
    background-image:url('../../assets/images/black-nav-bg.png');
  }

  .navbar-brand {
    color:white!important;
  }

  .navbar-brand:focus,
  .navbar-brand:hover,
  .nav-link:focus, 
  .nav-link:hover {
    color:#F1563F;
  }

  .navbar-expand-lg .navbar-nav a.nav-link {
    padding:8px 16px;
  }

  .navbar-toggler,
  .navbar-toggler:hover,
  .navbar-toggler:focus {
    outline: 0px!important;
    border:0px!important;
    border-color: white!important;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
  }

  .offcanvas-header .btn-close {
    filter: brightness(0) invert(1);
  }