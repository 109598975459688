@font-face {
  font-family: "Liberator";
  src: local('Liberator'), url("./assets/fonts/Liberator.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 300;
  font-style: normal;
  src:  url("./assets/fonts/KlinicSlabLight.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 300;
  font-style: italic;
  src:  url("./assets/fonts/KlinicSlabLightIt.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 400;
  font-style: normal;
  src:  url("./assets/fonts/KlinicSlabMedium.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 400;
  font-style: italic;
  src:  url("./assets/fonts/KlinicSlabBookIt.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 500;
  font-style: normal;
  src:  url("./assets/fonts/KlinicSlabMedium.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 500;
  font-style: italic;
  src: url("./assets/fonts/KlinicSlabMediumIt.ttf");
}
@font-face {
  font-family: "Klinic Slab";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/KlinicSlabBold.ttf");
}
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: "Klinic Slab";
  color:#000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image:url('./assets/images/bg.png');
  background-size:100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Klinic Slab"!important;
}

h1, h2, h3, h4, h5 {
  font-family: "Liberator", sans-serif!important;
}

h1, .amplify-heading--1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #F1563F!important;
}

h1 span {
  color:#000;
}


h2, .amplify-heading--2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  padding:9px 15px 6px;
  font-family: "Liberator";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color:white;
  background:#000;
  border-radius: 8px;
  border: 0px !important;
}
.btn:hover {
  background:#F1563F;
}

.inner-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:10px;align-items: start;
}
.inner-card .left {
  position: relative;
}

.inner-card .left .QR {
  position: absolute;
  width: 95px;
  height: 96px;
  bottom:50px;
  right:10px;
}


.inner-card .left .mainImage {
  width:100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.inner-card .right h2 {
  color: #FFF;
  background-image:url('./assets/images/title-orange-bg.png');
  background-size:100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  width: 100%;
  padding:11px 0;
}

.inner-card .right .item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding:5px 12px;
}
.inner-card .right .hours {
  padding:5px 12px;
}

.inner-card .right .label {
  color:#F1563F;
  flex-shrink: 0;
  margin-right:10px;
}

.inner-card .bottom {
  background-color: #FFF;
  background-image:url('./assets/images/bottom-bg.png');
  background-repeat: no-repeat;
  background-size:100% 100%;
  padding:20px 0 5px;
  margin-top:10px;
  color:#FFF;
  background-position: bottom center;
}
.inner-card .bottom .label {
  color:#FFF;
}

.inner-card .btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.8fr;
  gap:10px;
}
.inner-card .btn-wrapper .btn {
  display: block;
  width:100%;
}

label, .amplify-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  marign-bottom:5px;
}

.amplify-fieldset--outlined {
  border:0px;
  padding:0px;
}

input, .amplify-input, textarea, .amplify-textarea, .amplify-searchfield__search, .amplify-field__show-password, .checkbox-wrapper {
  border: 1px solid #C9C9C9;
  background:#FFF;
  padding:15px;
  border-radius:0;
  margin: 0!important;
}

.form-control-lg {
  border-radius:0px;
}

.checkbox-wrapper {
  min-height: 150px;
  overflow-y: scroll;
}

.ticket {
  color: #FFF;
  background-color: #F1563F;
  text-align: center;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ticket .title {
  padding:10px 10px 0;
}

.ticket .bottom {
  background-image:url('./assets/images/ticket-bg.png');
  background-size: 100% calc(100% + 50px);
  background-repeat: no-repeat;
  background-position: 0 -50px;
  padding: 40px 11px 11px;
}

.ticket.event .bottom {
  background-position:bottom;
}


.ticket .bottom .btn-wrapper {
  margin-top:20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:10px;
}

.ticket .bottom .btn-wrapper .btn {
  background-color:#FFF;
  color:#000;
}

.ticket .bottom .item {
  margin:10px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ticket .bottom .item .label {
  color:#F1563F;
}

.amplify-searchfield {
  width: 100%;
}


.signin .col-sm-12 {
  height:100vh;
}

.signin .left {
  background-image:url('./assets/images/left-bg.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: right center;
  z-index:2;
  width:calc(50% + 100px);
  margin-right:-100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color:#FFF;
}
.signin form > .amplify-flex {
  align-items: center;
  width:100%;
  padding-top:75px;
}

.signin form > .amplify-flex >fieldset {
  width:100%;
}

.signin.signup form > .amplify-flex > .amplify-flex {
  width:100%;
}

.signin .amplify-flex .amplify-textfield {
  width:100%;
}

.signin form  .amplify-flex > .amplify-button.amplify-field-group__control,
.signin .btn {
  width:auto;
  padding:9px 44px 6px;
  font-family: "Liberator", sans-serif!important;
  border-radius: 8px;
  background: #F1563F;
  color:#FFF!important;
  border:0px!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.signup .amplify-heading--4 {
display:none;
}

.signup [data-amplify-authenticator] [data-amplify-authenticator-confirmsignup] .amplify-label {
  font-size: 30px;
  text-transform:uppercase;
  font-weight: 700;
  color: #F1563F!important;
  font-family: "Liberator", sans-serif!important
}

.signin [data-amplify-authenticator] [data-amplify-authenticator-confirmsignup] > .amplify-flex {
  padding-top:20px;
}

.signin .left .amplify-text {
  color:#FFF
}
.signin .left .amplify-text a {
  color:#F1563F;
  text-decoration: none;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding:0;
}
[data-amplify-authenticator] [data-amplify-router] {
  background-color:transparent;
  border:0;
  color:#FFF;
  padding:0;
  box-shadow:none;
}
.signin label {
  color:#FFF;
}
[data-amplify-authenticator] [data-amplify-footer] .amplify-button {
  padding:0!important;
  display: inline;
  background:transparent!important;
  color:#FFF;
  opacity:1;
  margin-top:10px;
  font-weight:400!important;
  font-style: italic;
}

.signin .right  {
  color:#FFF;
  background-image:url('./assets/images/hog-bg.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: right center;
  z-index:1;
  padding-left:100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signin .right .welcome {
  color:#FFF;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signin .right .subheader {
  color:#FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top:20px;
}

.signin.signup .amplify-tabs {
  display: none;
}

.signin.signup [data-amplify-authenticator] {
  margin-bottom:16px;
}

.landing .location h2,
.landing .purpose h2 {
  color: #FFF;
  background-image:url('./assets/images/title-orange-bg.png');
  background-size:100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding:10px 20px 15px;
  display: inline-block;
  margin-bottom: 30px;
  margin-top:44px;
}

.landing .location .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
}

.landing .location .wrapper .item {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landing .location .wrapper .item .label {
  color: #F1563F;
}

.landing .location .wrapper .item .hours {
  width: 80%;
}

.landing .location .wrapper .item .hours .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.landing .location .wrapper .item .hours .item .hours_label {
  font-weight:700;
}

.landing .info h2 {
  font-family: "Klinic Slab"!important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom:15px;
}

.landing .btn-wrapper .btn {
  color: #FFF;
  background-color:transparent;
  background-image:url('./assets/images/title-orange-bg.png');
  background-size:100% 100%;
  padding:6px 22px 9px;
  background-repeat: no-repeat;
  background-position: center;
  margin:0px 25px;
}


.landing .btn-wrapper .btn:first-of-type {
  margin-left:0;
}

.landing .btn-wrapper .btn:last-of-type {
  margin-right:0;
}

.img-box {
  background: #eeeeee;
  width: 273.25px;
  height: 169.71px;
  position: absolute;
  left: 308.75px;
  top: 182px;
  display: block;
}
.hours {
  padding-bottom:0.5em;
}

.hours label {
  padding-right:0.5em;
  min-width: 100px;
  text-align: right;
}

.navbar-brand > img {
  max-height: 150px;
  width:100%;
}
@media only screen and (max-width: 992px)  {
  body {
    background-size: 100% auto;
  }
  .navbar-brand > img {
    max-height: 90px;
  }
  .signin .col-sm-12 {
      height: auto;
      min-height: 50vh;
      padding:20px 0;
  }
  .signin .right {
    background-size:150%;
      background-position: left center;
      z-index: 2;
      width: 100%;
      margin-right: 0px;
      order:1;
      padding:0;
  }
  .signin .left {
    background-size:150%;
      background-position: left center;
      z-index: 2;
      width: 100%;
      margin-right: 0px;
      order:2;
  }
  [data-amplify-authenticator] [data-amplify-form] {
    padding:0PX 20px;
  }
  .signin .right .subheader {
    text-align: center;
    margin-left: 0;
    margin-top: 20px;
  }

  .landing .location .wrapper {
    grid-template-columns: 1fr;
  }

  .lineBreak {
    width:100%;
  }
  .landing .btn-wrapper .btn {
    display: block;
    margin: 20px 0;
  
  }
  .landing .location .wrapper .item {
    margin-bottom:20px;
  }
  .landing .location .wrapper .item:last-of-type {
    margin-bottom:0px;
  }
}
@media only screen and (max-width: 992px) {
  .inner-card {
    grid-template-columns: 1fr;
  }
}